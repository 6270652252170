export const breakpoints = [32, 48, 64, 80].map(n => n + 'em')

export const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 96, 128, 192, 256, 384, 512, 640, 768]

export const fontSizes = [12, 14, 16, 18, 20, 24, 30, 36, 48, 60, 72]

export const fontWeights = { 
  lighter: 200,
  light: 300,
  regular: 400,
  normal: 500,
  bold: 700
}

export const colors = Object.assign(
  {},
  {
    primary: ['#2A3E7D', '#622BFF', '#2368A2', '#3183C8', '#63A2D8', '#AAD4F5', '#EFF8FF'],
    secondary: '',
    link: '#cacaca',
    black: '#313131',
    white: '#fdfdfd',
  }
)

export const fonts = {
  primary: "'Muli', sans-serif",
  secondary: "'Muli', sans-serif",
}

export default {
  colors,
  fonts,
  fontSizes,
  breakpoints,
  fontWeights,
  space
}