import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { breakpoints } from "../../theme"

const Container = styled("div")`
  position: relative;
  overflow: hidden;
`
const CoverImage = styled(Img)`
  height: 90vh;
  object-fit: "cover";
`
const TextContainer = styled("div")`
  position: absolute;
  top: 50%;
  left: 0%;
  margin: 0 5vw;
  transform: translateY(-50%);
  max-width: 50%;
  background: linear-gradient(
    90deg,
    rgba(42, 62, 125, 0.36),
    rgba(98, 43, 255, 0.36)
  );
  padding: 20px;

  @media (max-width: ${breakpoints[0]}) {
    max-width: 100%;
  }
`

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        homeImage: file(relativePath: { eq: "homeImage.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 900) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Container>
        <CoverImage
          fluid={data.homeImage.childImageSharp.fluid}
          imgStyle={{ objectPosition: "center center" }}
        />
        <TextContainer>{children}</TextContainer>
      </Container>
    )}
  />
)
