import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Section, Title, Para } from "../basic"
import { Flex, Box } from "rebass"

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          banner1Image: file(relativePath: { eq: "banner1.png" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          banner2Image: file(relativePath: { eq: "banner2.png" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          banner3Image: file(relativePath: { eq: "banner3.png" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          banner4Image: file(relativePath: { eq: "banner4.png" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Section>
          <Title textAlign="center" style={{ marginBottom: "40px" }}>
            Associations and links
          </Title>
          <Para style={{ whiteSpace: "pre-line" }}>
            We have extensive networks with accreditation bodies within the
            field of consulting, local academic institutions and many Digital
            Technology providers within Media City (Salford).
            <br />
            <br />
            Our services are not only limited to offering recommendations
            helping clients getting the right Digital Delivery models but
            utilising our ensure partner relationships that have helped deliver
            bespoke Digital solutions following our needs assessments
          </Para>
          <Flex
            justifyContent="space-around"
            alignItems="center"
            mt={7}
            flexDirection={["column", "row", "row", "row"]}
          >
            <Box my={[5,0,0,0]} style={{ maxWidth: "200px" }} width={[1, 1 / 4, 1 / 4, 1 / 4]}>
              <Img
                fluid={data.banner1Image.childImageSharp.fluid}
                imgStyle={{ objectPosition: "center center" }}
              />
            </Box>
            <Box my={[5,0,0,0]} style={{ maxWidth: "200px" }} width={[1, 1 / 4, 1 / 4, 1 / 4]}>
              <Img
                fluid={data.banner2Image.childImageSharp.fluid}
                imgStyle={{ objectPosition: "center center" }}
              />
            </Box>
            <Box my={[5,0,0,0]} style={{ maxWidth: "80px" }} width={[1, 1 / 4, 1 / 4, 1 / 4]}>
              <Img
                fluid={data.banner3Image.childImageSharp.fluid}
                imgStyle={{ objectPosition: "center center" }}
              />
            </Box>
            <Box my={[5,0,0,0]} style={{ maxWidth: "140px" }} width={[1, 1 / 4, 1 / 4, 1 / 4]}>
              <Img
                fluid={data.banner4Image.childImageSharp.fluid}
                imgStyle={{ objectPosition: "center center" }}
              />
            </Box>
          </Flex>
        </Section>
      )}
    />
  )
}
