import React from "react"
import { fonts, fontSizes, fontWeights, colors } from "../../theme"
import { Section, Title } from "../basic"
import { Flex, Box } from "rebass"
import styled from "styled-components"

const BorderBox = styled("div")`
  margin-top: 24px;
  overflow: hidden;
  text-align: center;
  max-height: 14.5rem; /* (Number of lines you want visible) * (line-height) */
  line-height: 1.2rem;
  font-size: ${fontSizes[2]}px;
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.light};
  color: ${colors.white};
  word-spacing: 3px;
`
const groups = [
  {
    src: "/img/requirment.svg",
    name: "requirment",
    title: "Requirements led versus IT",
    description:
      "The company has a portfolio of saving companies money and delivering the solutions that fit the organisational setting of the company.",
  },
  {
    src: "/img/landmark-solid.svg",
    name: "landmark",
    title: "A unique approach",
    description:
      "Our clients get access to decades of experience and expertise in both the theoretical and practical aspects of problem solving.",
  },
  {
    src: "/img/chess-solid.svg",
    name: "relationship",
    title: "Long-term relationship",
    description:
      "We provide each client with a specific Sharepoint site from day one which will contain a project plan, templates and act as a repository for all their documentation.",
  },
  {
    src: "/img/clone-solid.svg",
    name: "clone",
    title: "Soft-systems methodology",
    description:
      "Our proven methodology is guided by Peter Checkland’s “Soft-systems methodology”. Using our founder’s experience, we can provide templates which you can adopt immediately.",
  },
]
export default () => (
  <Section>
    <Title>What makes us unique</Title>
    <Flex
      flexDirection={["column", "column", "row"]}
      alignItems="strech"
      justifyContent="center"
      flexWrap="wrap"
      px={[2, 9, 6, 9]}
    >
      {groups.map((item, index) => (
        <Box
          key={index}
          width={[1, 1, 1 / 2, 1 / 2]}
          px={[4, 4, 6]}
          py={[4, 4, 6]}
        >
          <Box
            px={6}
            py={7}
            style={{
              boxShadow: "0px 2px 8px 0px rgba(92, 91, 92, 0.16)",
              fontSize: "20px",
              textAlign: "center",
              color: "#fff",
              height: "100%",
              borderRadius: "15px",
              background: "linear-gradient(45deg,#2a3e7d,#622bff)",
            }}
          >
            <div>
              <img
                alt={item.name}
                src={item.src}
                style={{ maxWidth: "90px" }}
              />
            </div>
            {item.title}
            <BorderBox>{item.description}</BorderBox>
          </Box>
        </Box>
      ))}
    </Flex>
  </Section>
)
