import React from "react"
import { Flex, Box } from "rebass"
import FacebookBoxIcon from "mdi-react/FacebookBoxIcon"
import LinkedinBoxIcon from "mdi-react/LinkedinBoxIcon"
import styled from "styled-components"
import { Link } from "gatsby"
import { breakpoints } from "../theme"

const IconContainer = styled("span")`
  color: #fff;
  cursor: pointer;
`
const Container = styled("div")`
  background: linear-gradient(45deg, #2a3e7d, #622bff);
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 20;
`
const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  @media (max-width: ${breakpoints[1]}) {
    margin: 8px 0;
  }
  &:hover {
    color: grey;
  }
`
const NewNavLink = styled("a")`
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  @media (max-width: ${breakpoints[1]}) {
    margin: 8px 0;
  }
  &:hover {
    color: grey;
  }
`

const Footer = ({ siteTitle }) => (
  <Container>
    <Flex
      flexDirection={["column-reverse", "column-reverse", "row", "row"]}
      justifyContent="space-between"
      px={[3, 3, 5, 5]}
      p={2}
      alignItems="center"
    >
      <NavLink to="/policy/cookiepolicy/">Cookies</NavLink>
      <NewNavLink
        href="https://www.iubenda.com/privacy-policy/33662537"
        target="new"
      >
        Privacy Policy
      </NewNavLink>
      <Flex
        flexDirection="column"
        alignItems={["center", "center", "flex-end", "flex-end"]}
        order={[2, 2, 0, 0]}
        p={3}
        flex={1}
      >
        <Box>
          <a href="https://www.facebook.com/anmcdigital/" target="new">
            <IconContainer>
              <FacebookBoxIcon />
            </IconContainer>
          </a>
          <a href="http://linkedin.com/in/nasir-akram" target="new">
            <IconContainer>
              <LinkedinBoxIcon style={{ marginLeft: "10px" }} />
            </IconContainer>
          </a>
        </Box>
      </Flex>
    </Flex>
  </Container>
)
export default Footer
