import React from "react"
import { Title, Section, Para, Button } from "../basic"
import styled from "styled-components"
import { Flex } from "rebass"
import { breakpoints, colors } from "../../theme"

const Image = styled("img")`
  top: 50%;
  right: ${props => (props.isSmallScreen ? "-40%" : 0)};
  position: absolute;
  max-height: 100%;
  padding: 20px;
  transform: translateY(-50%);
`

const Input = styled("input")`
  z-index: 2;
  color: rgba(255, 255, 255, 0.48);
  padding: 16px 32px;
  font-size: 25px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 30px;
  max-width: 658px;
  background: transparent;
  border: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.63);
  text-align: center;
  outline: none;
  @media (max-width: ${breakpoints[1]}) {
    font-size: 18px;
  }
`

export default () => (
  <Section alternateBg style={{ position: "relative", overflow: "hidden" }}>
    <form
      name="Whitepaper"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/download/"
    >
      <Flex flexWrap="wrap" alignItems="center" flexDirection="column">
        <Image src="/img/Group6.svg" />
        <Title
          textAlign="center"
          style={{ marginBottom: "10px", color: colors.white }}
        >
          Download our whitepaper
        </Title>
        <Para style={{ textAlign: "center", color: colors.white }}>
          Our whitepaper contains years of insight and is aimed to bring you up
          to date with latest trends in organisational practices and identifying
          untapped market potential.
        </Para>
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Whitepaper" />
        <Input
          type="email"
          placeholder="Your email address"
          name="email"
          id="email"
          required="required"
          aria-label="Your email address"
          autoComplete="email"
        />
        <Button
          type="submit"
          style={{ padding: "12px 46px", fontSize: "20px", zIndex: 2 }}
        >
          Get the whitepaper
        </Button>
      </Flex>
    </form>
  </Section>
)
