import React from "react"
import { Flex } from "rebass"
import Link from "gatsby-link"
import Scrollchor from "react-scrollchor"
import ArrowTopIcon from "mdi-react/ArrowTopIcon"
import styled, { createGlobalStyle } from "styled-components"
import Navbar from "./Navbar"
import { breakpoints } from "../theme"

const GlobalStyle = createGlobalStyle`
  .logo{
    height: 56.7px;
    max-width: 145px;
    margin-bottom: 0;
    @media(max-width: 500px){
      max-width: 108px;
    }
  }
`
const Container = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
  background-color: transparent;
  transition: all 0.3s ease-in-out;

  & .white-logo {
    display: none;
  }

  @media (max-width: ${breakpoints[0]}) {
    & .white-logo {
      display: ${props => (props.alwaysDarkLogo ? "none" : "block")};
    }
    & .black-logo {
      display: ${props => (props.alwaysDarkLogo ? "block" : "none")};
    }
  }
`
const ScrollToTopButton = styled("button")`
  position: fixed;
  right: 50px;
  bottom: 65px;
  background-color: #fff;
  color: #313131;
  border: none;
  outline: none;
  box-shadow: 0px 2px 8px 0px rgba(92, 91, 92, 0.46);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 100;
  display: none;
  padding: 0;

  [data-scroll="scrolled"] & {
    display: block;
  }

  @media (max-width: ${breakpoints[1]}) {
    width: 40px;
    height: 40px;
    right: 20px;
    bottom: 20px;

    & > svg {
      height: 20px;
      width: 20px;
    }
  }
`

const Header = ({ alwaysDarkLogo }) => (
  <Container alwaysDarkLogo={alwaysDarkLogo}>
    <Scrollchor to="#___gatsby">
      <ScrollToTopButton>
        <ArrowTopIcon />
      </ScrollToTopButton>
    </Scrollchor>
    <GlobalStyle />
    <Flex
      flexDirection="row"
      bg="transparent"
      justifyContent="space-between"
      px={[3, 3, 2, 5]}
      alignItems="center"
      mt={3}
      mb={[0, 0, 3, 3]}
    >
      <Flex alignItems="center">
        <Link to="/" style={{ textDecoration: "none" }}>
          <svg
            className="black-logo"
            width="130"
            height="50"
            viewBox="0 0 199 57.655"
          >
            <defs>
              <linearGradient
                id="linear-gradient"
                x1="0.5"
                x2="0.5"
                y2="1"
                gradientUnits="objectBoundingBox"
              >
                <stop offset="0" stopColor="#2a3e7d" />
                <stop offset="0.483" stopColor="#4535bc" />
                <stop offset="1" stopColor="#622bff" />
              </linearGradient>
            </defs>
            <g
              id="Group_10"
              data-name="Group 10"
              transform="translate(-116 -50.345)"
            >
              <text
                id="ANMC"
                transform="translate(190 98)"
                fill={(typeof window !== 'undefined' && window.location.pathname === "/") ? "#fff" : "#313131"}
                fontSize="41"
                fontFamily="Muli-Bold, Muli"
                fontWeight="700"
              >
                <tspan x="0" y="0">
                  ANMC
                </tspan>
              </text>
              <g
                id="Group_3"
                data-name="Group 3"
                transform="translate(-135.13 -183.808)"
              >
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M300.241,264.289v-3.348h-5.669l-15.538-26.788L263.5,260.941h-5.669v3.348h3.727l-10.424,17.97h55.807l-10.423-17.97Zm-21.207-20.514,10.012,17.165H269.023Zm20.2,34.639h-40.4l8.238-14.125H291Z"
                  fill="url(#linear-gradient)"
                />
              </g>
            </g>
          </svg>
          <svg
            className="white-logo"
            width="117.94"
            height="33.79"
            viewBox="0 0 117.94 33.79"
          >
            <g
              id="Group_11"
              data-name="Group 11"
              transform="translate(-116 -50.21)"
            >
              <text
                id="ANMC"
                transform="translate(160.94 78)"
                fill="#f2f2f2"
                fontSize="24"
                fontFamily="Muli-Bold, Muli"
                fontWeight="700"
              >
                <tspan x="0" y="0">
                  ANMC
                </tspan>
              </text>
              <g
                id="Group_3"
                data-name="Group 3"
                transform="translate(116 50.21)"
              >
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M280.955,252.455v-2.034h-3.443l-9.436-16.268-9.436,16.268H255.2v2.034h2.263l-6.33,10.913h33.892l-6.33-10.913ZM268.076,240l6.08,10.424H262Zm12.269,21.036H255.807l5-8.578h14.532Z"
                  transform="translate(-251.13 -234.153)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>
        </Link>
      </Flex>
      <Navbar />
    </Flex>
  </Container>
)
export default Header
