import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'styled-components'
import Theme from '../theme'
import './layout.css'
import Header from './header';
import Footer from './footer';

const Layout = ({ alwaysDarkLogo, children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ThemeProvider theme={Theme}>
        <>
        <Header alwaysDarkLogo={alwaysDarkLogo}/>
          <div style={{minHeight: "calc(100vh - 166px)"}}>{children}</div>
          <Footer/>
        </>
      </ThemeProvider>
    )}
  />
)

export default Layout
