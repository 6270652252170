import React from "react"
import CookieConsent from "react-cookie-consent"

export default () => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      style={{
        background: "rgb(217, 217, 217)",
        flexDirection: "column"
      }}
      contentStyle={{
        flex: "auto",
        margin: "10px 18px"
      }}
      buttonStyle={{
        display: "flex",
        flexDirection: "column",
        color: "#fff",
        background: "linear-gradient(45deg, rgb(42, 62, 125), rgb(98, 43, 255))",
        fontSize: "13px",
        padding: "8px 15px",
        margin: "10px 18px",
        borderRadius: "5px"
      }}
      cookieName= "gatsby-gdpr-google-analytics">
      <div
        style={{
          fontWeight: 600,
          color: "#000",
          fontSize: "15px",
          marginBottom: "10px",
        }}
      >
        About cookie on this site
      </div>
      <div style={{ fontSize: "13px", color: "#525252" }}>
        This website uses cookies to enhance the user experience.
      </div>
    </CookieConsent>
  )
}
