import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { Title, Para } from "../basic"
import { Flex, Box } from "rebass"

export default () => (
  <Box>
    <StaticQuery
      query={graphql`
        query {
          OurServicesImage: file(relativePath: { eq: "ourServices.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Flex
          flexWrap="wrap"
          bg="#eceffc"
          alignItems="center"
          flexDirection={["column", "row", "row", "row"]}
          style={{ maxWidth: "1450px", margin: "0 auto" }}
        >
          <Box
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            px={[5, 5, 7, 8]}
            py={[6, 6, 0, 0]}
          >
            <Title textAlign="center" style={{ marginBottom: "10px" }}>
              Our Services
            </Title>
            <Para>
              Our services are geared around enabling successful Digital
              transformations by empowering the business to change their
              structures and processes alongside their people. We believe this
              change is a pre-requisite for delivering the true value of
              Technology.
            </Para>
            <Para>
              Our services portfolio comprises the best tools within business
              analysis and consultancy which we constantly update with
              best-practice.
            </Para>
          </Box>
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2]} order={[-1, 0, 0, 0]}>
            <Img
              style={{ maxHeight: "450px" }}
              fluid={data.OurServicesImage.childImageSharp.fluid}
              imgStyle={{ objectPosition: "top center" }}
            />
          </Box>
        </Flex>
      )}
    />
  </Box>
)
