import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { Section, Title } from "../basic"
import { Box } from "rebass"

export default () => (
  <StaticQuery
    query={graphql`
      query {
        bannerImage: file(relativePath: { eq: "banners.png" }) {
          childImageSharp {
            fluid(maxWidth: 1500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Section>
        <Title textAlign="center" style={{ marginBottom: "40px" }}>
          Our Clients
        </Title>
        <Box style={{ maxWidth: "800px", margin: "0 auto" }}>
          <Img
            fluid={data.bannerImage.childImageSharp.fluid}
            imgStyle={{ objectPosition: "center center" }}
          />
        </Box>
      </Section>
    )}
  />
)
