import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { Title, Para } from "../basic"
import { Flex, Box } from "rebass"

export default () => (
  <Box>
    <StaticQuery
      query={graphql`
        query {
          whoweareImage: file(relativePath: { eq: "Whoweare.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Flex
          flexWrap="wrap"
          bg="#eceffc"
          alignItems="center"
          flexDirection={["column", "row", "row", "row"]}
          style={{ maxWidth: "1450px", margin: "0 auto" }}
        >
          <Box
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            px={[5, 5, 7, 8]}
            py={[6, 6, 0, 0]}
          >
            <Title textAlign="center" style={{ marginBottom: "10px" }}>
              Who we are
            </Title>
            <Para>
              Founded in 2013, we are a specialist consultancy that has helped
              numerous clients avoid the pitfalls of large IT investments by
              empowering them to lead suppliers to delivering the digital
              solutions they actually need rather than ones off the shelf.
            </Para>
            <Para>
              We have an extensive portfolio of successful assignments and a
              growing base of clients across many industries and disciplines
              ranging from Financial Services, Tech sector, Healthcare to the
              Public sector where we have made the largest impact.
            </Para>
          </Box>
          <Box order={[-1, 0, 0, 0]} width={[1, 1 / 2, 1 / 2, 1 / 2]}>
            <Img
              style={{ maxHeight: "450px" }}
              fluid={data.whoweareImage.childImageSharp.fluid}
              imgStyle={{ objectPosition: "top center" }}
            />
          </Box>
        </Flex>
      )}
    />
  </Box>
)
