import React, { useState } from "react"
import { Flex } from "rebass"
import styled from "styled-components"
import { breakpoints, colors } from "../../theme"
import { Section, Title, Button } from "../basic"

const BorderBox = styled(Flex)`
  border: ${props => (props.isSelected ? "none" : "2px solid #1a1a1a")};
  margin: 20px;
  margin-bottom: 5px;
  height: 100px;
  width: 100px;
  cursor: pointer;
  background: ${props =>
    props.isSelected
      ? `linear-gradient(45deg, ${colors.primary[0]}, ${colors.primary[1]})`
      : colors.white};

  & > img {
    height: 50px;
    width: 50px;
    margin-bottom: 0;
    filter: ${props => (props.isSelected ? "invert(1)" : "none")};
  }

  @media (max-width: ${breakpoints[1]}) {
    height: 50px;
    width: 50px;

    & > img {
      height: 25px;
      width: 25px;
    }
  }
`
const Container = styled(Flex)`
  flex-direction: column;
  color: grey;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  color: #313131;
`
const Input = styled("input")`
  color: #313131;
  padding: 16px 32px;
  min-width: 68%;
  border-color: #101010;
  font-size: 14px;
  width: 100%;
  margin: 20px 0;
  max-width: 800px;

  @media (max-width: ${breakpoints[1]}) {
    margin: 10px 0;
  }
`

export default () => {
  const [appointmentType, updateAppointmentType] = useState("Phone")
  return (
    <Section>
      <Title  textAlign="center"  style={{ marginBottom: "40px" }}>
        Schedule a free appointment
      </Title>
      <form
        name="Appointment"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/success/"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Appointment" />
        <input
          type="hidden"
          name="type"
          value={appointmentType}
          onChange={() => {}}
        />
        <Flex flexDirection="column" p={4} alignItems="center">
          <div style={{ color: "#313131" }}>Select an appointment type:</div>
          <Flex flexDirection="row" mb="40px">
            <Container>
              <BorderBox
                alignItems="center"
                justifyContent="center"
                isSelected={appointmentType === "Phone"}
                onClick={() => updateAppointmentType("Phone")}
              >
                <img src="/img/phone.svg" alt="phone icon" />
              </BorderBox>
              Phone
            </Container>
            <Container>
              <BorderBox
                alignItems="center"
                justifyContent="center"
                isSelected={appointmentType === "Email"}
                onClick={() => updateAppointmentType("Email")}
              >
                <img src="/img/email.svg" alt="email icon" />
              </BorderBox>
              E-mail
            </Container>
            <Container>
              <BorderBox
                alignItems="center"
                justifyContent="center"
                isSelected={appointmentType === "InPerson"}
                onClick={() => updateAppointmentType("InPerson")}
              >
                <img src="/img/calendar.svg" alt="calendar icon" />
              </BorderBox>
              In-person
            </Container>
          </Flex>
          <Input
            type="text"
            placeholder="Your name"
            name="name"
            id="name"
            required="required"
            aria-label="name"
            autoComplete="name"
          />
          <Input
            type="number"
            placeholder="Your phone"
            name="phone"
            id="phone"
            required="required"
            aria-label="Your phone"
            autoComplete="phone"
          />
          <Input
            type="email"
            placeholder="Your e-mail"
            name="email"
            id="email"
            required="required"
            aria-label="Your e-mail"
            autoComplete="email"
          />
          <Button type="submit" gradientBg style={{ padding: "8px 40px" }}>
            Get in touch
          </Button>
        </Flex>
      </form>
    </Section>
  )
}
