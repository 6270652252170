import React from "react"
import Link from "gatsby-link"
import { StaticQuery, graphql } from "gatsby"
import { breakpoints, fonts, fontSizes, fontWeights, colors } from "../../theme"
import { Section, Title } from "../basic"
import { Flex, Box } from "rebass"
import styled from "styled-components"

const BorderBox = styled("div")`
  color: rgb(88, 88, 88);
  margin-top: 24px;
  overflow: hidden;
  max-height: 14.5rem; /* (Number of lines you want visible) * (line-height) */
  line-height: 1.2rem;
  font-size: ${fontSizes[2]}px;
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.light};
  color: ${colors.black};
  word-spacing: 3px;
  text-align: justify;
`
const Button = styled("button")`
  background: transparent;
  border: 1px solid #653bee;
  font-size: 16px;
  color: #653bee;
  outline: none;
  cursor: pointer;
  padding: 12px 22px;
  border-radius: 7px;
`
const Container = styled(Flex)`
  @media (max-width: ${breakpoints[2]}) {
    & > div:nth-child(3) {
      display: none;
    }
  }
  @media (max-width: ${breakpoints[1]}) {
    & > div {
      display: none;
    }

    & > div:nth-child(1) {
      display: block;
    }
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { fields: { slug: { regex: "/casestudy/" } } }
          limit: 3
        ) {
          edges {
            node {
              frontmatter {
                title
                name
              }
              html
            }
          }
        }
      }
    `}
    render={data => (
      <Section>
        <Title>Case Studies</Title>
        <Container flexDirection={["column", "column", "row"]} alignItems="strech" justifyContent="center">
          {data.allMarkdownRemark.edges.map((casestudy, index) => (
            <Box
              key={casestudy.node.frontmatter.title}
              width={[1, 1, 1 /2, 1/3]}
              mx={[0, 0, 4]}
              my={[4, 4, 0]}
            >
              <Link
                to={`/casestudy/${casestudy.node.frontmatter.title}`}
                style={{ textDecoration: "none" }}
              >
                <Box
                  px={6}
                  py={7}
                  style={{
                    boxShadow: "0px 2px 8px 0px rgba(92, 91, 92, 0.16)",
                    fontSize: "20px",
                    color: "rgba(0, 0, 0, 0.8901960784313725)",
                    height: '100%'
                  }}
                >
                  {casestudy.node.frontmatter.name}
                  <BorderBox
                    dangerouslySetInnerHTML={{ __html: casestudy.node.html }}
                  />
                </Box>
              </Link>
            </Box>
          ))}
        </Container>

        <Flex mt={7} justifyContent="center">
          <Link to="/casestudies">
            <Button>View all case studies</Button>
          </Link>
        </Flex>
      </Section>
    )}
  />
)
