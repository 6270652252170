import React from "react"
import styled from "styled-components"
import {
  colors,
  fonts,
  fontSizes,
  fontWeights,
  space,
  breakpoints,
} from "../theme"

export const Title = styled("h2")`
  font-size: ${fontSizes[6]}px;
  line-height: ${fontSizes[8]}px;
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.regular};
  color: ${props => (props.isLight ? colors.white : colors.black)};
  @media (max-width: ${breakpoints[1]}) {
    text-align: ${props =>(props.textAlign ? props.textAlign : 'inherit')};
  }
  @media (max-width: ${breakpoints[0]}) {
    font-size: ${fontSizes[5]}px;
    line-height: ${fontSizes[5]}px;
    text-align: center;
  }

`

export const Para = styled("p")`
  font-size: ${fontSizes[4]}px;
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.light};
  color: ${props => (props.isLight ? colors.white : colors.black)};
  word-spacing: 3px;
  text-align: justify;
  line-height: 26px;
  button > & {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints[1]}) {
    font-size: ${fontSizes[2]}px;
  }
`

export const StyledSection = styled("section")`
  padding: ${space[8]}px 5vw;
  text-align: left;
  background: ${props =>
    props.alternateBg
      ? `linear-gradient(45deg, ${colors.primary[0]}, ${colors.primary[1]})`
      : colors.white};
`
export const Section = ({ children, ...others }) => (
  <StyledSection {...others}>
    <div style={{ maxWidth: "1450px", margin: "0 auto" }}>{children}</div>
  </StyledSection>
)

export const Button = styled("button")`
  font-size: 16px;
  background: ${props =>
    props.gradientBg
      ? `linear-gradient(45deg, ${colors.primary[0]}, ${colors.primary[1]})`
      : "white"};
  color: ${props => (props.gradientBg ? colors.white : "#653bee")};
  outline: none;
  border: none;
  cursor: pointer;
  padding: 8px 13px;
  border-radius: 7px;
`

export const OnlyLargeScreenBox = styled("div")`
  @media (max-width: 45em) {
    display: none;
  }
`
export const OnlySmallScreenBox = styled("div")`
  @media (min-width: 45em) {
    display: none;
  }
`

export default {
  Title,
  Para,
  Section,
  Button,
  OnlyLargeScreenBox,
  OnlySmallScreenBox
}
