import React from "react"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"
import { Title, Para } from "../basic"
import { Flex, Box } from "rebass"

export default () => (
  <Box>
    <StaticQuery
      query={graphql`
        query {
          whatwedoImage: file(relativePath: { eq: "Whatwedo.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => (
        <Flex
          flexWrap="wrap"
          bg="#eceffc"
          alignItems="center"
          flexDirection={["column", "row", "row", "row"]}
          style={{ maxWidth: "1450px", margin: "0 auto" }}
        >
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2]}>
            <Img
              style={{ maxHeight: "450px" }}
              fluid={data.whatwedoImage.childImageSharp.fluid}
              imgStyle={{ objectPosition: "bottom center" }}
            />
          </Box>
          <Box
            width={[1, 1 / 2, 1 / 2, 1 / 2]}
            px={[5, 5, 7, 8]}
            py={[6, 6, 0, 0]}
          >
            <Title textAlign="center" style={{ marginBottom: "10px" }}>
              What we do
            </Title>
            <Para>
              Every new client assignment begins with a conversation about the
              clients perception of the problem leading to an opportunity for us
              to gain a first-hand view of the organisational setting to define
              the scope of work and validate the problem.
            </Para>
            <Para>
              We always minimise the disruption to the clients business as usual
              work whilst we start getting to grips with the relationship
              between the problem, the people, the processes, the systems and
              the customers. The output is always a useful by product that many
              of our clients retain for communicating what they do even when we
              leave.
            </Para>
          </Box>
        </Flex>
      )}
    />
  </Box>
)
