import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Title } from "../components/basic"
import HomeSection from "../components/index/HomeSection"
import CasestudySection from "../components/index/CasestudySection"
import ApointmentSection from "../components/index/ApointmentSection"
import WhoWeAreSection from "../components/index/WhoWeAreSection"
import WhatWeDoSection from "../components/index/WhatWeDoSection"
import AssociationSection from "../components/index/AssociationSection"
import ClientSection from "../components/index/ClientSection"
import WhatMakesUsSection from "../components/index/WhatMakesUsSection"
import OurServicesSection from "../components/index/OurServicesSection"
import WhitePaperSection from "../components/index/WhitePaperSection"
import CookieBanner from "../components/CookieBanner"
import styled from "styled-components"
import { colors, fonts, fontSizes, fontWeights, breakpoints } from "../theme"

export const Para = styled("p")`
  font-size: ${fontSizes[4]}px;
  font-family: ${fonts.primary};
  font-weight: ${fontWeights.light};
  color: ${colors.white};
  word-spacing: 3px;
  button > & {
    margin-bottom: 0;
  }

  @media (max-width: ${breakpoints[1]}) {
    font-size: ${fontSizes[3]}px;
    text-align: center;
  }
`

export default () => (
  <Layout hideHeader>
    <SEO />
    <CookieBanner />
    <HomeSection>
      <Title style={{ color: colors.white, marginBottom: "10px" }}>
        Problem solving, not just consulting
      </Title>
      <Para>
        We strive to help our clients solve their most complex problems.
      </Para>
    </HomeSection>
    <WhoWeAreSection />
    <WhatWeDoSection />
    <OurServicesSection />
    <CasestudySection />
    <WhitePaperSection />
    <WhatMakesUsSection />
    <ClientSection />
    <AssociationSection />
    <div id="appointment">
      <ApointmentSection />
    </div>
  </Layout>
)
