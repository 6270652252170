import React from "react"
import { Flex } from "rebass"
import Link from "gatsby-link"
import styled from "styled-components"
import { Location } from "@reach/router"
import Scrollchor from "react-scrollchor"
// import { OnlyLargeScreenBox } from "./basic"

/*const Button = styled("button")`
  font-size: 20px;
  background: transparent;
  color: #fff;
  outline: none;
  border: none;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    color: #c492b1;
  }

  [data-scroll="scrolled"] & {
    color: #2a3e7d;
  }
`*/
const BorderButton = styled("button")`
  font-size: 16px;
  background: white;
  color: #653bee;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 8px 13px;
  border-radius: 7px;

  [data-scroll="scrolled"] & {
    box-shadow: 0px 2px 8px 0px rgba(92, 91, 92, 0.16);
  }
`

export default () => (
  <div
    style={{
      padding: ".9375rem 0",
      letterSpacing: ".7px",
    }}
  >
    <Flex alignItems="center" justifyContent="flex-end">
      {/* <OnlyLargeScreenBox>
        <Scrollchor to="#about">
          <Button children="About" />
        </Scrollchor>
      </OnlyLargeScreenBox> */}
      <Location>
        {({ location }) => (
          <>
            {location.pathname === "/" ? (
              <Scrollchor to="#appointment">
                <BorderButton children="Get in touch" />
              </Scrollchor>
            ) : (
              <Link to="/#appointment">
                <BorderButton children="Get in touch" />
              </Link>
            )}
          </>
        )}
      </Location>
    </Flex>
  </div>
)
